
import { Options, Vue } from 'vue-class-component';
import { Emit } from "vue-property-decorator";
import { apiBrandGetListName } from '../services/tpsApi/tpsApiBrand';
import { apiListMenuGetItem } from '../services/tpsApi/tpsApiListMenuItem';
import DatepickerLite from "vue3-datepicker-lite";

@Options({
  components: {
    DatepickerLite
  },
  emits: ["search"]
})


export default class ReportDailyTranSumSearchMenu extends Vue {
    formData: {} = {};
    brandSelectOptions: {} = {};
    cardAreaOptions: {} = {};
    txDateWarningText = "";

    REPORT = "4"; //report=4，其他-日交易總表

    //Date picker setting
    startdate_tx = {
        id: "startdate_tx",
        class: "md-input myDateInput md-datepicker w-10-rem",
        locale: {
          format: "YYYY-MM-DD",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        },
        changeEvent: (value) => {
          this.formData["startdate_tx"] = value;
          this.dateCheck("tx");
        }
    }

    enddate_tx = {
        id: "enddate_tx",
        class: "md-input myDateInput md-datepicker w-10-rem",
        locale: {
          format: "YYYY-MM-DD",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        },
        changeEvent: (value) => {
          this.formData["enddate_tx"] = value;
          this.dateCheck("tx");
        }
    }

    async mounted() {
        this.brandSelectOptions = await apiBrandGetListName();
        this.cardAreaOptions = await apiListMenuGetItem({menuName: "愛心卡", listName: "地區"});
    }

    datepickerActive(pID) {
        let datepicker = document.getElementById(pID)
        if(datepicker) (datepicker as HTMLFormElement).focus(); 
    }

    //Check if start date less than end date 
    dateCheck(dateColumn) {
        var start;
        var end;

        if(dateColumn == "tx" && this.formData["startdate_tx"] && this.formData["enddate_tx"]) {
            start = new Date(this.formData["startdate_tx"]);
            end = new Date(this.formData["enddate_tx"]);
            start>end?this.txDateWarningText="起始時間不能大於結束時間":this.txDateWarningText="";
        } 
    }

    search() {  
        let txStart = new Date(this.formData["startdate_tx"]);
        let txEnd = new Date(this.formData["enddate_tx"]);
        if(txStart > txEnd) {
            this.txDateWarningText="起始時間不能大於結束時間"
        }   
        else if (this.formData["startdate_tx"] && !this.formData["enddate_tx"] ||
            !this.formData["startdate_tx"] && this.formData["enddate_tx"]) {
            this.txDateWarningText = "請輸入完整起迄時間";
        }
        else {
            this.txDateWarningText = "";
        }

        if(this.txDateWarningText == "") {
            let data = {
                startdate_tx: this.formData["startdate_tx"],
                enddate_tx: this.formData["enddate_tx"],
                brand_id: this.formData["brand_id"],
                card_area: this.formData["card_area"]
            };

            this.doSearch(data);
        }
    }

    @Emit("search")
    doSearch(pData) {
        return pData;
    }
}
